.section-blog-listing {
  .sidebar-widget {
    max-width: 310px;
  }

  // Posts Listing
  &.posts {
    .container {
      @include breakpointMax(sm) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    aside {
      gap: 24px;
      flex-wrap: wrap;
      margin-bottom: 54px;

      .posts-cat-btn {
        width: 100%;
        text-align: left;
        box-shadow: -2px -2px 40px rgba(94, 94, 118, 0.25);
        border: 1px solid #ced4da;
        background-color: $white;
        color: $gray;
        padding: 9px 16px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          height: 16px;
          width: 16px;
          background-image: url('../../../public/arrow-down.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        &:hover {
          border: 1px solid $secondary !important;
          background-color: $white !important;
          color: $gray !important;
        }
      }

      .posts-cat {
        font-weight: 500;

        >div+div {
          margin-left: 30px;
        }

        .term-lists__item {
          span {
            cursor: pointer;
          }

          &.active {
            color: $secondary;
            border-bottom: 2px solid $secondary;
            padding-bottom: 12px;
          }

          +.term-lists__item {
            margin-left: 30px;
          }
        }
      }

      .posts-search {
        width: 100%;

        @include breakpoint(md) {
          width: 300px;
        }

        input {
          height: 52px;
          border-radius: 8px;
          padding: 16px;
          box-shadow: -2px -2px 40px rgba(94, 94, 118, 0.25);

          @include breakpoint(md) {
            height: 56px;
          }

          &:focus {
            border-color: $secondary;
            box-shadow: -2px -2px 40px rgba(94, 94, 118, 0.25);
          }

          &::placeholder {
            color: $gray;
          }
        }
      }
    }

    .blog-listing-wrap {
      margin-bottom: -24px;

      .col-md-6 {
        margin-bottom: 24px;
      }

      .empty-state {
        width: 100%;
        max-width: 750px;
        box-shadow: -2px -2px 40px rgba(94, 94, 118, 0.25);
        text-align: center;

        .empty-state__message {
          margin-top: 0;
        }
      }

      .post-item {
        border-radius: 24px;
        box-shadow: -2px 10px 50px rgba(94, 94, 118, .12);

        @include breakpoint(md) {
          height: 100%;
        }

        .post-thumbnail {
          padding: 0;

          figure {
            height: 240px;
          }

          span {
            height: 100% !important;
            width: 100% !important;
            padding-bottom: 0 !important;
          }

          img {
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            border-top-right-radius: 24px;
            border-top-left-radius: 24px;
            margin-bottom: 0;
          }
        }

        .post-content {
          padding: 30px 24px 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 240px);

          &-info {
            font-size: 12px;
            color: $light-gray;
            gap: 24px;
            margin-bottom: 12px;
          }

          a {
            text-decoration: none;
          }

          h2 {
            color: $gray;
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 12px;
          }

          &-footer {
            border-top: 1px solid #dbe4ef;
            padding-top: 16px;

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              display: flex;
              gap: 6px;
              flex-wrap: wrap;

              li {
                span {
                  display: inline-block;
                  line-height: 1;
                  background-color: lighten($secondary, 50);
                  color: lighten($secondary, 15);
                  padding: 8px;
                  border-radius: 8px;
                  font-size: 14px;
                }

                &:nth-child(2n) {
                  span {
                    background-color: lighten($primary, 40);
                    color: darken($primary, 10);
                  }
                }
              }
            }
          }
        }
      }

      .load-more-wrap {
        text-align: center;
        margin-top: 36px;

        button {
          position: relative;
          
          svg {
            display: none;
          }

          &.loading-btn {
            svg {
              display: inline;
              position: absolute;
              top: 15px;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}

.layout-d {
  display: block;

  @include breakpoint(md) {
    display: flex;
  }

  & .image-border {
    padding-bottom: 25px;
    margin-bottom: 60px;
    border-bottom: 1px solid #e0e0e0;
  }

  & .content-border {
    border-bottom: 0;

    @include breakpoint(md) {
      padding-bottom: 25px;
      margin-bottom: 60px;
      border-bottom: 1px solid #e0e0e0;
    }
  }

  & .post-footer {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    li:last-child {
      margin-right: 31px;
    }

    button {
      padding: 0px 10px;
      min-width: 100%;
      margin: 0;
      border-radius: 100px;
      margin-top: 5px;
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;

      &:disabled {
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        opacity: 1;
      }
    }
  }

  & .post-content {
    width: 100%;

    a {
      text-decoration: none;
    }

    h2 {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: #333333;

      &:hover {
        color: $accent;
      }
    }

    @include breakpoint(md) {
      width: 62%;
    }
  }
}

.post-thumbnail {
  figure {
    margin-top: 15px;

    span {
      height: 0 !important;
      padding-bottom: 100% !important; 

      img {
        object-fit: cover;
      }
    }
  }

  @include breakpoint(md) {
    padding: 0;
    padding-left: 23px;
    padding-bottom: 24px;

    figure {
      margin-top: 0px;
    }
  }
}

.section-blog-list {
  padding-top: 0;
  padding-bottom: 0;
}

.reading-time {
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: $dark;
}

// Posts Cat Offcanvas
.posts-cat-offcanvas {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  .offcanvas-body {
    padding: 20px 40px;

    .d-flex {
      flex-wrap: wrap;
      gap: 20px;

      + .d-flex {
        margin-top: 20px;
      }

      .term-lists__item {
        width: 100%;

        &.active {
          span {
            color: $secondary;
            border-bottom: 2px solid $secondary;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}