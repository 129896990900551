.section-multi-col-ti {
  position: relative;
  background: $dark;
  &.has-background {
    overflow: hidden;
    // clip-path: polygon(100% 10%, 30% 100%, 100% 100%);
    // background-blend-mode: overlay;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          59.37deg,
          #431075 -73.36%,
          #421788 -34.34%,
          #4126b1 56.1%,
          #412dc2 103.99%
        ),
        $dark;
      clip-path: polygon(0 40%, 100% 28%, 100% 100%, 0% 100%);

      background-blend-mode: overlay;
      z-index: 1;
      @include breakpoint(md) {
        clip-path: polygon(100% 10%, 30% 100%, 100% 100%);
      }
    }
    .container {
      position: relative;
      z-index: 2;
    }
  }

  h2 {
    margin-bottom: 8px;
    text-align: center;
    @include breakpoint(lg) {
      text-align: left;
      font-size: 48px;
      line-height: 56px;
    }
  }
  .icon-boxs {
    margin-top: -50px;
    @include breakpoint(md) {
      margin-top: -108px;
    }

    &__item {
      margin-top: 50px;
      @include breakpoint(md) {
        margin-top: 108px;
      }
      h5 {
        margin-bottom: 8px;
        text-align: center;
        @include breakpoint(md) {
          text-align: left;
        }
      }
      .list-icon {
        & img {
          height: 44px;
        }

        text-align: center;
        margin-bottom: 16px;

        @include breakpoint(md) {
          width: 44px;
          margin-left: 0;
        }
      }
      .desc {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        @include breakpoint(md) {
          text-align: left;
        }
      }
      .btn-wrapper {
        text-align: center;
        margin-top: 13px;
        @include breakpoint(md) {
          text-align: left;
        }
      }
      .box-content {
        max-width: 255px;
        @include breakpointMax(md) {
          margin: 0 auto;
        }
      }
      &.col-md-4 {
        &:nth-child(2),
        &:nth-child(3n + 2) {
          .box-content {
            @include breakpoint(md) {
              margin: 0 auto;
            }
          }
        }
        &:nth-child(3n) {
          .box-content {
            @include breakpoint(md) {
              margin-left: auto;
            }
          }
        }
      }
    }
    &__content {
    }
  }
}
