/* ---------------------------------------------------------------------
 wrap your form code by class .style-material to add material effect
--------------------------------------------------------------------- */
.gform_wrapper {
  .gform_heading {
    .gform_title {
    }
    .gform_description {
    }
  }
  .validation_error {
    // @extend .alert;
    // @extend .alert-danger;
  }

  .gform_body {
    .gform_fields {
      list-style: none;
      & > .gfield {
        margin-bottom: 16px;
      }
    }
    .gfield {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="color"],
      input[type="date"],
      input[type="datetime-local"],
      input[type="email"],
      input[type="month"],
      input[type="number"],
      input[type="range"],
      input[type="search"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="week"],
      input[type="range"],
      input[type="file"],
      textarea {
        // @extend .form-control;
      }
      // select
      .ginput_container_select {
        @extend .custom-select;
        background: rgba(52, 58, 64, 0.06)
          url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0L5 6L0 0L10 0Z' fill='%230065F2'/%3E%3C/svg%3E%0A")
          no-repeat right 1rem center;
      }
      // ginput_container_radio
      .ginput_container_radio {
        .gfield_radio {
          margin: 0;
          list-style: none;

          input {
            display: none;
          }

          input[type="radio"] + label {
            padding: 0 0 0 36px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            position: relative;
            margin-bottom: 0;
            line-height: rem-calc(24);
            left: 0px;
            top: 0;
            white-space: normal;
            width: 100%;

            &:after,
            &:before {
              position: absolute;
              left: 0;
              top: 0;
            }

            &:before {
              content: "";
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background: $gray-100;
              @include transition(all, 0.3s, linear);
            }

            &:after {
              content: "";
              width: 24px;
              height: 24px;
              @include border-radius(50%);
              opacity: 0;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23000000'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 50% 50%;
              @include transition(opacity, 0.3s, linear);
            }
          }

          input[type="radio"]:checked + label {
            &:after {
              opacity: 1;
            }
          }
        }
      }
      // ginput_container_checkbox
      .ginput_container_checkbox {
        .gfield_checkbox {
          input {
            display: none;
          }
          margin: 0;
          list-style: none;

          input[type="checkbox"] + label {
            padding: 0 0 0 36px;
            display: inline-block;

            vertical-align: middle;
            cursor: pointer;
            position: relative;
            margin-bottom: 0;
            line-height: rem-calc(24);
            width: auto;
            left: 0px;
            top: 0;
            white-space: normal;
            width: 100%;

            &:after,
            &:before {
              position: absolute;
              left: 0;
              top: 0;
            }

            &:before {
              content: "";
              width: 24px;
              height: 24px;
              background: $gray-100;
              @include transition(all, 0.3s, linear);
            }

            &:after {
              content: "";
              width: 24px;
              height: 24px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgNS41OGw0LjUwNyA0LjUwOCA4LjIyMS04LjIyIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=");
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 50% 50%;
              opacity: 0;
              @include transition(opacity, 0.3s, linear);
            }
          }

          input[type="checkbox"]:checked + label {
            &:after {
              opacity: 1;
            }
          }
        }
      }
      // hidden_label
      &.hidden_label {
        & > .gfield_label {
          display: none;
        }
      }
      // Error
      &.gfield_error {
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="color"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="email"],
        input[type="month"],
        input[type="number"],
        input[type="range"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        input[type="week"],
        input[type="range"],
        input[type="file"],
        textarea {
          box-shadow: inset 0 -1px 0 0 $danger;
          @include placeholder {
            color: $danger;
          }
        }
        lable,
        .gfield_label {
          color: $danger;
        }
        .validation_message {
          color: $danger;
        }
      }
      .gfield_description {
        // @extend .small;
        // @extend .text-muted;
      }

      .ginput_complex {
        @extend .d-flex;
        @extend .flex-wrap;
        margin: 0 -10px;
        & > span {
          width: 100%;
          display: block;
          padding: 0 10px;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
          @include breakpoint(md) {
            margin-bottom: 0px;
          }
        }

        &.has_first_name.no_middle_name.has_last_name,
        .has_first_name.has_middle_name.no_last_name {
          & > span {
            width: 100%;
            @include breakpoint(md) {
              width: 50%;
            }
          }
        }
        &.has_first_name.has_middle_name.has_last_name {
          & > span {
            width: 100%;
            @include breakpoint(md) {
              width: 33.3333%;
            }
          }
        }
      }
      .ginput_container {
        position: relative;
        .eye-icon {
          width: 16px;
          height: 16px;
          // background-image:url('./assets/images/eye-fill.svg');
          background-repeat: no-repeat;
          right: 16px;
          @include y_center;
          &.show {
            // background-image:url('./assets/images/eye-slash-fill.svg');
          }
        }
      }
    }
  }
  .gform_footer {
    position: relative;
    display: inline-block;
    .gform_button {
      // @extend .btn;
      // @extend .btn-primary;
      min-width: 150px;
    }
  }

  &.newsletter-form_wrapper {
    .newsletter-form {
      position: relative;
      .gform_body {
        width: calc(100% - 150px);
      }
      .gform_footer {
        width: 150px;
        position: absolute;
        bottom: 0;
        right: 0;
        .gform_button {
          height: 40px;
        }
      }
    }
    &.gform_validation_error {
      .newsletter-form .gform_footer {
        bottom: 21px;
      }
    }
  }
}
// confirmation message
.gform_confirmation_message {
  // @extend .alert;
  // @extend .alert-success;
}
/* Spinner */

.gform_ajax_spinner {
  margin-left: 20px;
  border: 2px solid rgba($black, 0.7);
  border-left: 4px solid rgba($white, 1);
  animation: spinner 1.1s infinite linear;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 8px;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// sytle material
.style-material {
  .gform_wrapper {
    .gform_body {
      .gform_fields {
        & > .gfield {
          position: relative;
          .gfield_label {
            position: absolute;
            top: 8px;
            left: 16px;
            @include transition(all 0.5s ease);
          }
          &.no-material-effect {
            .gfield_label {
              position: static;
            }
          }

          .ginput_complex {
            & > span {
              position: relative;
              display: block;
              label {
                position: absolute;
                top: 8px;
                left: 16px;
                @include transition(all 0.5s ease);
              }
            }
          }
          &.focused {
            .gfield_label {
              top: -10px;
              // @extend .small;
            }
            .ginput_complex > span label {
              top: -10px;
              // @extend .small;
            }
          }
        }
      }
    }
  }
}
