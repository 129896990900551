.section-custom-list {
  & .lists__item {
    padding-bottom: 0px;
    & .box-content {
      display: flex;
      margin-bottom: 15px;
      & .icon-boxs__content {
        padding-left: 16px;
        width: calc(100% - 30px);
        & .desc {
          margin-bottom: 10px;
        }
      }
      & .index {
        background: linear-gradient(to right, #683fce 100%, #4f83ca 100%);
        color: #4f83ca;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        text-decoration: none;
        border-radius: 50%;

        &:before {
          content: "";
          position: absolute;
          border-radius: 50%;
          background: white;
          width: 26px;
          height: 26px;
          left: 2px;
        }
        span {
          z-index: 2;
          position: relative;
          background: -webkit-linear-gradient(#683fce, #4f83ca);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  & + .section-apppy-btn {
    .btn {
      margin-left: 48px;
    }
  }
}
.section-apppy-btn {
  .btn {
    min-width: 240px;
    &:hover {
      background-color: $accent;
      border-color: $accent;
    }
    &:focus {
      background: linear-gradient(to right, #683fce 100%, #4f83ca 100%);
      border: transparent;
      position: relative;
      width: 240px;
      height: 53px;
      z-index: 0;

      &:before {
        content: "";
        position: absolute;
        background: $accent;
        width: 234px;
        height: 46px;
        top: 3px;
        right: 3px;
        border-radius: 8px;
        z-index: -1;
        padding-left: 2px;
      }
    }
  }
}
