@import '404';
@import 'search-results';
.site-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
}

img {
    max-width:100%;
    height:auto;
   
}
ul {
    margin: 20px 0;
}
// sections
.section {
    padding: 50px 0;

    @include breakpoint(md) {
        padding: 60px 0;
    }

    // @include breakpoint(xl) {
    //     padding: 80px 0;
    // }

    &.p-small {
        padding: 36px 0;

        // @include breakpoint(md) {
        //     padding: 50px 0;
        // }

        // @include breakpoint(xl) {
        //     padding: 80px 0;
        // }
    }
    &.p-large {

        padding: 60px 0;

        @include breakpoint(md) {
            padding: 80px 0;
        }

        @include breakpoint(xl) {
            padding: 165px 0;
        }
    }
}

.container {
    @include breakpoint(md) {
        padding-right: ($grid-gutter-width / 2);
        padding-left: ($grid-gutter-width / 2);
    }
}

.secondary-bg {
    background:$secondary-bg;
}

.screen-reader-text {
    display:none;
}
.section-title {
    margin-bottom: 47px;
    .section-desc {
        margin-top: 35px;
    }
}
// link
.link-icon {
    text-decoration: none;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    img, svg {
        display: inline-block;
        vertical-align:middle;
        margin-left:6px;
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        color: $primary !important;
        svg {
            color: $primary !important;
        }
    }
}

figure {
    margin: 0;
    line-height: 0;
}

[data-aos=fade-down] {
    transform: translate3d(0,-50px,0);
}

.career-details {
    .col-10 {
        @include breakpoint(md) {
            width: 88.537%;
        }
    }
    .col-md-8 {
        width: 100%;
        @include breakpoint(md) {
            width: 88.537%;
            margin-left: 8.33333333%;
            margin-right: auto;
        }
    }
}