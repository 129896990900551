.section-text-block {
    &.has-background {
        background-color: $dark;
    }
    &.has-gradient {
        position: relative;
        background-color: $dark;
        overflow: hidden;
        &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(59.37deg, #431075 -73.36%, #421788 -34.34%, #4126B1 56.1%, #412DC2 103.99%),$dark;
        clip-path: polygon(0 40%, 100% 28%, 100% 100%, 0% 100%);
        background-blend-mode: overlay;
        z-index: 1;
            @include breakpoint(md) {
                clip-path: polygon(100% 10%, 30% 100%, 100% 100%);
            }
        }
        .container {
            position: relative;
            z-index: 2;
        }
    }
    
    .desc {
        margin-top: 35px;
        max-width: 730px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .content {
        margin-top: 35px;
        @include breakpoint(md) {
            margin-top: 0px;
            margin-left: auto;
        }

        
       
        
    }

    
}