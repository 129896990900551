.section-breadcrumb {
  padding-top: 20px;

  @include breakpoint(md) {
    padding-top: 30px;
  }

  @include breakpoint(xl) {
    padding-top: 40px;
  }

  .container {
    @include breakpointMax(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  ol.breadcrumb {
    flex-wrap: nowrap;
    margin-bottom: 0;

    .breadcrumb-item {
      font-size: 14px;
      line-height: 1.5;
      position: relative;

      &:not(.active) {
        padding-right: 10px;

        @include breakpointMax(sm) {
          max-width: 102px;
        }
      }

      &::after {
        color: $light-gray;
        position: absolute;
        top: 0;
        right: -7px;
        padding: 0;
      }

      a {
        text-decoration: none;
      }

      + .breadcrumb-item {
        padding-left: 15px;
      }

      &.active {
        color: $light-gray;
      }
    }
  }

}