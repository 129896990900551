.section-banner {
  background: linear-gradient(180deg, #0038d2 0%, #002ba0 100%);
  padding: 50px 0;
  position: relative;
  @include breakpoint(lg) {
    height: 600px;
  }
  @include breakpoint(xl) {
    height: 724px;
  }
  .banner-image {
    margin: 0 0 30px 0;
    @include breakpoint(lg) {
      margin: 0;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      max-width: 60%;
    }
    // &[data-aos^=fade][data-aos^=fade].aos-animate {
    //     top: 50px;
    // }
  }
  .section-content {
    max-width: 570px;
    color: $white;
    position: relative;
    z-index: 2;
    h1 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -0.01em;
      @include breakpoint(lg) {
        font-size: 80px;
        line-height: 88px;
      }
    }
    .h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 29px;
      max-width: 524px;
      margin-bottom: 0;
      line-height: 30px;
    }
    .btn-wrapper {
      margin-top: 42px;
      .btn {
        // min-width: 254px;
      }
    }
  }
}
