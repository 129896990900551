// @import "slick/slick";
// @import "slick/slick-theme";

.carousel-control {
  &-prev,
  &-next {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $primary;
    bottom: auto;
    @include y_center;
    opacity: 1;
    cursor: pointer;
    @include transition(all 0.5s ease);
    @include breakpoint(md) {
      width: 60px;
      height: 60px;
    }
    &:hover {
      background: darken($primary, 10);
    }
  }
  &-prev-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='32' viewBox='0 0 18 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8579 1.99995L1.71573 16.1421L15.8579 30.2842' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 8px;
    height: 16px;
    @include breakpoint(md) {
      width: 15px;
      height: 30px;
    }
  }
  &-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='32' viewBox='0 0 18 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.14214 1.99995L16.2843 16.1421L2.14214 30.2842' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 8px;
    height: 16px;
    @include breakpoint(md) {
      width: 15px;
      height: 30px;
    }
  }
}
.carousel-control-prev {
  left: -15px;
}
.carousel-control-next {
  right: -15px;
}
.slick-dots {
  margin-top: 30px;
  position: static;
  @include breakpoint(md) {
    margin-top: 50px;
  }
  li {
    width: auto;
    height: auto;
    margin: 0 9px;
    button {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      min-width: auto;
      background: rgba(0, 0, 0, 0.3);
      &:before {
        display: none;
      }
    }
    &:hover,
    &.slick-active {
      button {
        background: $black;
      }
    }
  }
}
