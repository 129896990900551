.layout-m {
  margin-right: 0px;
  margin-bottom: 20px;
  @include breakpoint(md) {
    margin-right: 75px;
  }
}
.country-lists__item {
  margin-left: 24px;
  margin-bottom: 49px;
  @include breakpoint(md) {
    margin-left: 89px;
    margin-bottom: 49px;
  }
  & .list-icon {
    margin-right: 15px;
    & img {
      width: 48px;
      max-height: 48px;
      object-fit: cover;
    }
    @include breakpoint(md) {
      margin-right: 0;
    }
  }
}
.team-lists {
  a {
    color: inherit;
    text-decoration: none;
  }
}
.team-lists__item {
  display: flex;
  padding-bottom: 44px;
  margin-left: 15px;
  @include breakpoint(md) {
    margin-left: 0;
  }
  
  img {
    border-radius: 50%;
    object-fit: cover;
    @include breakpoint(md) {
    }

    border-radius: 50%;
  }
  .content {
    padding-left: 36px;
    h3 {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 31px;
      color: #bdbdbd;
    }
  }
}
.team-lists__item .content {
  padding-left: 36px;
  width: calc(100% - 88px);
  box-sizing: border-box;
}
.team-lists__item > span {
  width: 88px;
  height: 88px !important;
  border-radius: 50%;
}
