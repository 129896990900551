.section-contact-from {
  & .contact-sidebar {
    margin-bottom: 20px;
    & h3 {
      margin-bottom: 18px;
      color: #000000;
    }
    & p {
      margin-bottom: 0;
      color: #000000;
    }
    & a {
      text-decoration: none;
      color: black;
      &:hover {
        color: $accent;
      }
    }
  }
}
