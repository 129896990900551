.career-list {
  & .career-list__item {
    & .post-content {
      padding-top: 10px;
      @include breakpoint(md) {
        padding-top: 32px;
      }
      & a {
        text-decoration: none;
        margin-bottom: 0px;
        @include breakpoint(md) {
          margin-bottom: 11px;
        }
      }
    }
    & .date {
      @include breakpoint(md) {
        font-size: 15px;
      }
      @include breakpoint(lg) {
        font-size: 25px;
      }
    }
    & .post-meta-footer {
      color: #0038d2;
      padding-bottom: 10px;
      padding-top: 3px;
      span {
        &:not(:last-child) {
          &:after {
            content: '|';
            margin:0 5px;
          }
        }
      }
    }
    & .fwd-arrow {
      display: none;
      padding-top: 40px;
      @include breakpoint(md) {
        display: block;
        padding-left: 0px;
      }
      @include breakpoint(lg) {
        display: block;
        padding-left: 12px;
      }
      & a {
        & svg:hover {
          filter: invert(77%) sepia(54%) saturate(558%) hue-rotate(98deg)
            brightness(90%) contrast(89%);
        }
      }
    }
  }
}
.section-blog-listing {
  & .career-pagination {
    padding-top: 110px;
  }
}
