.wp-block-pullquote {
    border: 4px solid #000;
    border-left:none;
    border-right:none;
    padding:rem-calc(30 0);
    blockquote {
        border:none;
        margin:0;
        cite {
            text-transform:uppercase;
            font-size:rem-calc(16);
            color:$primary;
        }
    }
}