.section-post-body {
  > .container {
    @include breakpointMax(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  .post-body-wrap {
    @include breakpoint(lg) {
      padding-left: 50px;
      padding-right: 24px;
    }

    @include breakpoint(xl) {
      padding-left: 60px;
      padding-right: 32px;
    }

    .container {
      padding: 0;
    }

    .col-md-8.col-10 {
      width: 100%;
    }

    .social-icons {
      padding-bottom: 40px;
      border-bottom: 1px solid #d0dbef;
      margin-bottom: 40px;
    }
  }

  .recent-posts {
    margin-top: 32px;

    @include breakpoint(lg) {
      position: sticky;
      top: 10px;
      margin-top: 0;
    }

    .posts-wrap {
      margin-top: 20px;

      @include breakpoint(md) {
        margin-top: 24px;
      }

      .post {
        display: flex;
        gap: 16px;
        padding: 12px;
        border-radius: 24px;
        box-shadow: -2px 10px 50px rgba(94, 94, 118, 0.12);
        max-width: 450px;

        + .post {
          margin-top: 16px;
          
          @include breakpoint(md) {
            margin-top: 20px;
          }
        }

        &-img {
          height: 100px;
          width: calc(40% - 8px);

          span {
            height: 100% !important;
            width: 100% !important;
          }

          img {
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            border-radius: 24px;
          }
        }

        &-desc {
          width: calc(60% - 8px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 16px;

          a {
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            // margin-bottom: 16px;
            color: $gray;
            text-decoration: none;
            line-height: 1.2;
          }

          .date {
            font-size: 12px;
            color: $light-gray;
            display: block;
            line-height: 1;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

.site-wrapper.blog-details {
  overflow: unset;
}

main.blog-details {
  .section-post-body.post-single:last-child {
    margin-bottom: 20px;

    @include breakpoint(md) {
      margin-bottom: 32px;
    }

    @include breakpoint(xl) {
      margin-bottom: 40px;
    }
  }
}